import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
  ChakraProvider,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom'; // useNavigate 추가
import signInImage from "assets/img/LambdaServices_BG.png";
import axios from 'axios';
import theme from "theme/theme.js";

function SignIn() {
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // State to manage loading indicator
  const toast = useToast();
  const navigate = useNavigate();  // useNavigate 추가

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);  // Start loading
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_ENDPOINT}/login/`, {
            username,
            password
        });
        console.log(response.data)
        window.localStorage.setItem("token", response.data.token);
        
        toast({
            description: "성공적으로 로그인 했습니다.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
        window.location.replace('/');

        // Optional: Redirect or other actions here
    } catch (error) {
        console.error('Failed to login', error);
        toast({
            title: "로그인 실패",
            description: "유저 네임 혹은 패스워드가 잘 못되었습니다.",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);  // Stop loading regardless of the outcome
    }
  };

  const handleSignUp = () => {
    navigate('/signup'); // 회원가입 페이지로 이동
  };

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Flex position='relative' mb='40px'>
        <Flex
          h={{ sm: "initial", md: "75vh", lg: "85vh" }}
          w='100%'
          maxW='1044px'
          mx='auto'
          justifyContent='space-between'
          mb='30px'
          pt={{ sm: "100px", md: "0px" }}>
          <Flex
            alignItems='center'
            justifyContent='start'
            style={{ userSelect: "none" }}
            w={{ base: "100%", md: "50%", lg: "42%" }}>
            <Flex
              direction='column'
              w='100%'
              background='transparent'
              p='48px'
              mt={{ md: "150px", lg: "80px" }}>
              <Heading color={titleColor} fontSize='32px' mb='10px'>
                안녕하세요
              </Heading>
              <Text
                mb='36px'
                ms='4px'
                color={textColor}
                fontWeight='bold'
                fontSize='14px'>
                로그인을 위해서 유저 네임과 패스워드를 입력하세요.
              </Text>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  유저 네임
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='24px'
                  fontSize='sm'
                  type='text'
                  placeholder='유저 네임을 입력하세요'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)} // Set username
                  size='lg'
                />
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  패스워드
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='36px'
                  fontSize='sm'
                  type='password'
                  placeholder='패스워드를 입력하세요'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Set password
                  size='lg'
                />
                <Button
                  onClick={handleLogin}
                  fontSize='10px'
                  color='white'
                  type='submit'
                  bg='teal.300'
                  w='100%'
                  h='45'
                  mb='20px'
                  mt='20px'
                  isLoading={isLoading}  // Use isLoading state to control loading indicator
                  loadingText="로그인 중"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                  로그인
                </Button>
              </FormControl>
              <Button
                onClick={handleSignUp} // 회원가입 페이지로 이동
                fontSize='10px'
                color='white'
                bg='gray.500'
                w='100%'
                h='45'
                _hover={{
                  bg: "gray.400",
                }}
                _active={{
                  bg: "gray.600",
                }}
              >
                회원가입
              </Button>
            </Flex>
          </Flex>
          <Box
            display={{ base: "none", md: "block" }}
            overflowX='hidden'
            h='100%'
            w='40vw'
            position='absolute'
            right='0px'>
            <Box
              bgImage={signInImage}
              w='100%'
              h='100%'
              bgSize='cover'
              bgPosition='50%'
              position='absolute'
              borderBottomLeftRadius='20px'></Box>
          </Box>
        </Flex>
      </Flex>
    </ChakraProvider>
  );
}

export default SignIn;
